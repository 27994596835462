<template>
<div></div>
</template>
<script>
import LiffChecker from "@/utils/LiffChecker";
import abbottAndMixin from "@/mixins/liff/proj/abbott-and";

export default {
  mixins: [abbottAndMixin],
  components: {},
  async mounted() {
    const checker = new LiffChecker(this.$route.params.orgCode);
    this.lineData = await checker.getLineData();
    await this.init();
  },
  data() {
    return {
      lineData: {},
    };
  },
  computed: {},
  methods: {
    async init() {
      await this.submit(this.lineData.profile.userId)
    },
  }
}
</script>

<style lang="scss"></style>
